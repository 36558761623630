import axios from "../../axios"

const domain = process.env.VUE_APP_BE_DOMAIN;

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });

export default {
    verifyCustomerIdentification(customer_id, status){
        return axios.get(domain+"verifyCustomerIdentification/"+customer_id+"/"+status)
    },
    updateCustomerTier(customer_id, customerTier){
        return axios.get(domain+"updateCustomerTier/"+customer_id+"/"+customerTier)
    },
    resetPassword(userId){
        return axios.get(domain+"resetPassword/"+userId)
    },
    clearInvoices(){
        return axios.get(domain+"clearInvoices")
    },
    generateBulkInvoice(transactionType){
        return axios.get(domain+"generateBulkMerchantInvoiceForThePreviousMonth/"+transactionType)
    },
    getCustomerDetailsByPhoneNumber(phoneNumber){
        return axios.get(domain+"getCustomerDetailsByPhoneNumber/"+phoneNumber)
    },
    getOutletPerformance(startDate, endDate, transactionType){
        return axios.get(domain+"outletPerformance/"+startDate+"/"+endDate+"/"+transactionType);
    },
    getGiftRegistries() {
        return axios.get(domain+"giftRegistries");
    },
    getLoyaltyQuickStats(){
        return axios.get(domain+"loyaltyQuickStats");
    },
    getHomepageStats(dateFilter){
      return axios.get(domain+"getDashboardStats/"+dateFilter.start_date+"/"+dateFilter.end_date)
    },
    getOutletCategoryPerformanceStats(dateFilter) {
        return axios.get(domain+"outletCategoryPerformanceStats/"+dateFilter.start_date+"/"+dateFilter.end_date)
    },
    generateInvoice(start_date, end_date, outletId){
      return axios.get(domain+"generateInvoice/"+start_date+"/"+end_date+"/"+outletId)
    },
    getInvoices(filterData){
      return axios.get(domain+"getInvoices/"+filterData.outletId+"/"+filterData.transactionType+"/"+filterData.startDate+"/"+filterData.endDate+"?page="+filterData.page)
    },
    getDuplicates(){
      return axios.get(domain+"duplicates")
    },
    getApiClients() {
        return axios.get(domain+"getApiClients")
    },
    getVouchers(page, from_date,to_date, status, voucherType, outletId){
        return axios.get(domain+"vouchers/"+from_date+"/"+to_date+"/"+status+"/"+outletId+"?page="+page+"&voucherType="+voucherType)
    },
    exportIndividualVouchers(from_date, to_date, emailAddress) {
        return axios.get(domain+"exportIndividualVouchers/"+from_date+"/"+to_date+"/"+emailAddress)
    },
    exportIndividualVoucherRedemptions(from_date, to_date, emailAddress) {
        return axios.get(domain+"exportIndividualVoucherRedemptions/"+from_date+"/"+to_date+"/"+emailAddress)
    },
    exportInstitutionVoucherRedemptions(from_date, to_date, emailAddress) {
        return axios.get(domain+"exportInstitutionVoucherRedemptions/"+from_date+"/"+to_date+"/"+emailAddress)
    },
    exportCorporateVouchers(from_date, to_date, emailAddress) {
        return axios.get(domain+"exportCorporateVouchers/"+from_date+"/"+to_date+"/"+emailAddress)
    },
    exportGiftRegistries( emailAddress) {
        return axios.get(domain+"exportGiftRegistries/"+emailAddress)
    },
    getInstitutionVoucherRecipients(page, voucherId){
        return axios.get(domain+"institutionVoucherRecipients/"+voucherId+"?page="+page)
    },
    searchVoucherRecipient(keyword, voucherId) {
        return axios.get(domain+"searchVoucherRecipient/"+keyword+"/"+voucherId)
    },
    getInstitutionalVouchers(page, from_date,to_date, status) {
        return axios.get(domain+"institutionalVouchers/"+from_date+"/"+to_date+"/"+status+"?page="+page)
    },
    getInvoiceItemsByInvoice(invoiceId, page){
        return axios.get(domain+"getInvoiceItemsByInvoice/"+invoiceId+"?page="+page)
    },
    getVoucherStats(from_date,to_date){
        return axios.get(domain+"voucherStats/"+from_date+"/"+to_date)
    },
    downloadInvoiceStatement(invoiceId){
        return axios.get(domain+"downloadStatement/"+invoiceId)
    },
    getNewCustomers(){
      return axios.get(domain+"getNewCustomers")
    },
    getSegmentCustomers(segmentId, page) {
        return axios.get(domain+"getSegmentCustomers/"+segmentId+"?page="+page)
    },
    createSMS(smsData){
      return axios.post(domain+"createSMS",smsData)
    },
    createCustomerSegment(segmentData) {
        return axios.post(domain+"createCustomerSegment",segmentData)
    },
    getSMSCampaigns(){
      return axios.get(domain+"smsCampaigns")
    },
    getAllCustomerSegments() {
        return axios.get(domain+"getAllCustomerSegments")
    },
    refreshSegments(segmentId) {
        return axios.get(domain+"populateCustomerSegmentById/"+segmentId)
    },
    getPushNotificationCampaigns(){
        return axios.get(domain+"pushNotificationCampaigns")
    },

    exportInvoices(startDate, endDate, invoiceType){
        return axios.get(domain+"exportInvoices/"+startDate+"/"+endDate+"/"+invoiceType);
    },
    resendNetsuiteInvoice(netsuiteId){
        return axios.get(domain+"resendNetsuiteInvoice/"+netsuiteId);
    },
    exportNewCustomers(emailAddress){
      return axios.get(domain+"exportNewCustomers/"+emailAddress)
    },
    saveDuplicate(duplicateData){
      return axios.post(domain+"saveDuplicate",duplicateData)
    },
    deleteDuplicate(duplicateData){
      return axios.get(domain+"deleteDuplicate/"+duplicateData.id)
    },
    deleteCampaign(campaignId){
      return axios.get(domain+"deleteCampaign/"+campaignId)
    },
    mergeDuplicate(duplicateData){
      return axios.post(domain+"mergeDuplicate",duplicateData)
    },

    saveTax(taxData){
      return axios.post(domain+"saveTax",taxData)
    },
    deleteTax(taxData){
      return axios.get(domain+"deleteTax/"+taxData.tax_id)
    },
    getTaxes(){
      return axios.get(domain+"getTaxes")
    },
    getOutlets(){
      return axios.get(domain+"outlets")
    },
    getLoyaltySettings(){
      return axios.get(domain+"loyaltySettings")
    },
    getOutletVoucherCodes(page, outletId,startDate, endDate, status){
        return axios.get(domain+"outletVoucherCodes/"+outletId+"/"+startDate+"/"+endDate+"/"+status+"?page="+page);
    },
    getLotterySettings(){
      return axios.get(domain+"lotterySettings")
    },
    getCustomers(page, customerType){
      return axios.get(domain+"customers/"+customerType+"?page="+page)
    },
    registerCustomer(customerData){
      return axios.post(domain+"registerCustomer",customerData)
    },
    updateCustomerDetails(customerData){
      return axios.post(domain+"updateCustomerDetails",customerData)
    },
    sendVerificationCode(phone_number){
      return axios.get(domain+"sendVerificationCode/"+phone_number)
    },
    searchCustomerByPhoneNumber(phone_number){
      return axios.get(domain+"searchCustomerByPhoneNumber/"+phone_number)
    },
    searchOutletsByName(outletName){
      return axios.get(domain+"searchOutletsByName/"+outletName)
    },
    downloadStatement(filterData){
      return axios.get(domain+"downloadStatement/"+filterData.customer_id+"/"+filterData.start_date+"/"+filterData.end_date)
    },
    updateCustomerStatus(customerData){
      return axios.get(domain+"updateCustomerStatus/"+customerData.status+"/"+customerData.customerId)
    },

    updateOutletStatus(outletData){
      return axios.get(domain+"updateOutletStatus/"+outletData.status+"/"+outletData.outletId)
    },
    getCustomerDetails(customerId){
      return axios.get(domain+"getCustomerDetailsById/"+customerId)
    },
    getCustomerSummary(customerId){
      return axios.get(domain+"getCustomerSummary/"+customerId)
    },
    getCustomerRatings(){
      return axios.get(domain+"customerRatings")
    },
    getLotteryTransactions(){
      return axios.get(domain+"lottery")
    },
    getReferrals(page){
        return axios.get(domain+"getReferrals?page="+page);
    },
    getMerchantLoyaltySettings(){
      return axios.get(domain+"merchantLoyaltySettings")
    },
    getTransactions(filterData){
      return axios.get(domain+"getLoyaltyTransactions/"+filterData.from_date+"/"+filterData.to_date+"/"+filterData.outlet_id+"/"+filterData.transaction_type+"/"+filterData.approval_type+"/"+filterData.loyaltyRuleId+"?page="+filterData.page)
    },
    getBonusTransactions(filterData){
        return axios.get(domain+"bonusTransactions/"+filterData.from_date+"/"+filterData.to_date+"/"+filterData.loyaltyRuleId+"?page="+filterData.page)
    },
    getNonAccrualTransactions(filterData){
        return axios.get(domain+"nonAccrualPartnerTransactions/"+filterData.from_date+"/"+filterData.to_date+"/"+filterData.outlet_id+"/"+filterData.partner_id+"?page="+filterData.page)
    },
    getParkingTransactions(filterData){
      return axios.get(domain+"getParkingTransactions/"+filterData.from_date+"/"+filterData.to_date+"/"+filterData.payment_method+"?page="+filterData.page)
    },

    exportParkingTransactions(filterData){
      return axios.get(domain+"exportParkingTransactions/"+filterData.from_date+"/"+filterData.to_date+"/"+filterData.payment_method+"/"+filterData.email)
    },
    getCustomerTransactions(filterData){
      return axios.get(domain+"filterCustomerTransactionsById/"+filterData.customerId+"/"+filterData.from_date+"/"+filterData.to_date+"/"+filterData.outlet_id+"/"+filterData.transaction_type+"?page="+filterData.page)
    },
    getLoyaltyStats(){
      return axios.get(domain+"loyaltyStats")
    },
    getMerchantStats(outlet_id){
      return axios.get(domain+"merchantLoyaltyStats/"+outlet_id)
    },
    getLotteryStats(){
      return axios.get(domain+"getLotteryStats")
    },
    reverseTransaction(transaction){
      return axios.post(domain+"reverseTransaction",transaction)
    },
    awardPoints(transaction){
        return axios.post(domain+"awardPoints",transaction)
    },
    redeemPoints(transaction){
        return axios.post(domain+"redeemPoints",transaction)
    },
    activatePhoneNumber(customerData){
      return axios.post(domain+"activatePhoneNumber",customerData)
    },
    getOutletsByUserId(userId){
      return axios.get(domain+"getOutletsByUserId/"+userId)
    },
    getUsersByOutlets(userId){
        return axios.get(domain+"getUsersByOutlets/"+userId)
    },
    getOutletInvoices(outletId){
      return axios.get(domain+"getOutletInvoices/"+outletId)
    },
    getInvoiceById(invoiceId){
      return axios.get(domain+"getInvoiceById/"+invoiceId)
    },
    generateAllMerchantInvoiceForThePreviousMonth(outletId, transactionType){
        return axios.get(domain+"generateAllMerchantInvoiceForThePreviousMonth/"+transactionType+"/"+outletId)
    },

     getUsers(){
       return axios.get(domain+"users")
     },

    deleteUser(userData){
      return axios.get(domain+"deleteUser/"+userData.user_id)
    },
    updateUser(userData){
      return axios.post(domain+"updateUser",userData)
    },
    addCategory(categoryData){
        return axios.post(domain+"addCategory", categoryData)
    },

    saveUser(userData){
      return axios.post(domain+"saveUser", userData)
    },
    addOutletUser(userData){
        return axios.post(domain+"addOutletUser", userData)
    },
    addRole(roleData){
      return axios.post(domain+"addRole", roleData)
    },
    updateRole(roleData){
      return axios.post(domain+"updateRole", roleData)
    },
    getRoles(){
      return axios.get(domain+"getRoles")
    },
    getRoleById(role_id){
      return axios.get(domain+"getRole/"+role_id)
    },

    editCategory(categoryData){
      return axios.post(domain+"editCategory", categoryData)
    },

    getUsersByRole(usersData){
      return axios.post(domain+"getUsersByRole", usersData)
    },

    transferEpurse(customerData){
      return axios.post(domain+"transferEpurse", customerData)
    },

    deleteCategory(categoryData){
      return axios.post(domain+"deleteCategory", categoryData)
    },

    getCategories(){
      return axios.get(domain+"categories")
    },
    getOutletDetails(outletData){
      return axios.get(domain+"outlet/"+outletData.outlet_id)
    },

    getTopPerformingOutlets(){
      return axios.get(domain+"getTopPerformingOutlets")
    },

    addOutlet(outletData){
      return axios.post(domain+"addOutlet", outletData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },

    importCustomers(customersData){
      return axios.post(domain+"importWalletBalance", customersData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    importOutlets(outletsData){
      return axios.post(domain+"importOutlets", outletsData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },

    importVoucherCodes(voucherCodeData){
        return axios.post(domain+"importVoucherCodes", voucherCodeData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    saveOutletConversion(conversionData){
      return axios.post(domain+"saveOutletConversion", conversionData)
    },
    getOutletConversion(id){
      return axios.get(domain+"getOutletConversion/"+id)
    },

    updateLoyaltySettings(loyaltySettings){
      return axios.post(domain+"saveLoyaltySettings", loyaltySettings)
    },

    saveNotice(noticeboardData){
      return axios.post(domain+"addNoticeBoard", noticeboardData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },

    savePromotion(promotionData){
      return axios.post(domain+"addPromotion", promotionData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },

    deletePromotion(promotionId){
      return axios.get(domain+"deletePromotion/"+promotionId)
    },

    getPromotions(){
      return axios.get(domain+"promotions")
    },



    editNotice(noticeboardData){
      return axios.post(domain+"editNoticeBoard", noticeboardData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },

    deleteNotice(noticeboardId){
      return axios.get(domain+"deleteNoticeBoard/"+noticeboardId)
    },

    getNotices(){
      return axios.get(domain+"noticeBoards")
    },

    updateLotterySettings(lotterySettings){
      return axios.post(domain+"saveLotterySettings", lotterySettings)
    },
    saveGeneralSettings(generalSettings){
      return axios.post(domain+"saveGeneralSettings", generalSettings)
    },
    saveSMTPSettings(smtpSettings){
      return axios.post(domain+"saveSMTPSettings", smtpSettings)
    },
    getGeneralSettings(){
      return axios.get(domain+"generalSettings")
    },
    getSMTPSettings(){
      return axios.get(domain+"smtpSettings")
    },

    deleteOutlet(outletData){
      return axios.get(domain+"deleteOutlet/"+outletData.outlet_id)
    },
    editOutlet(outletData){
      return axios.post(domain+"editOutlet", outletData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },

  exportCustomers(customersData){
    return axios.get(domain+"exportCustomers/"+customersData.from_date+"/"+customersData.to_date+"/"+customersData.email)
  },

    exportTransactionsData(filterData){
      return axios.get(domain+"exportLoyaltyTransactions/"+filterData.from_date+"/"+filterData.to_date+"/"+filterData.outlet_id+"/"+filterData.transaction_type+"/"+filterData.username+"/"+ filterData.approval_status)
    },
    exportBonusTransactions(filterData) {
        return axios.get(domain+"exportBonusTransactions/"+filterData.from_date+"/"+filterData.to_date+"/"+filterData.loyalty_rule_id+"/"+filterData.username)
    },

    getCUGMembers(customerId){
        return axios.get(domain+"customerDependents/"+customerId);
    },
    getReportExports(customerId){
        return axios.get(domain+"reportExports");
    },
    removeCUGMember(customerId){
        return axios.get(domain+"removeCUGMember/"+customerId);
    },
    getLoyaltyMembersSummary(startDate, endDate){
        return axios.get(domain+"loyaltyMembers/"+startDate+"/"+endDate);
    },
    getMerchantsSummary(){
        return axios.get(domain+"merchantSummary");
    },
    getCustomerPerformance(startDate, endDate, transactionType, limit){
        return axios.get(domain+"customerPerformance/"+startDate+"/"+endDate+"/"+transactionType+"/"+limit);
    },
    uploadFile(file){
        return axios.post(domain+"uploadFile", file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    importVoucherRecipients(file){
        return axios.post(domain+"importVoucherRecipients", file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    createPushNotification(pushNotificationData){
        return axios.post(domain+"createPushNotification", pushNotificationData)
    },
    saveInstitution(institutionData){
        return axios.post(domain+"saveInstitution", institutionData)
    },
    editInstitution(institutionData){
        return axios.post(domain+"editInstitution", institutionData)
    },
    buyAdminVoucher(voucherData) {
        return axios.post(domain+"handleAdminVouchersPurchase",voucherData)
    },
    createParkingVoucher(voucherData) {
        return axios.post(domain+"createParkingVoucher",voucherData)
    },
    buyInstitutionalVouchers(voucherData) {
        return axios.post(domain+"buyInstitutionalVouchers",voucherData)
    },
    createRule(ruleData){
        return axios.post(domain+"createRule", ruleData)
    },
    editRule(ruleData) {
        return axios.post(domain+"editRule", ruleData)
    },
    getOutletProducts(outletId){
        return axios.get(domain+"outletProducts/"+outletId);
    },
    getPartners(){
        return axios.get(domain+"partners");
    },
    approveInstitutionVoucher(voucherId) {
        return axios.get(domain+"approveInstitutionVoucher/"+voucherId);
    },
    getPartnerTransactions(){
        return axios.get(domain+"partnerTransactions");
    },
    getRules() {
        return axios.get(domain+"loyaltyRules");
    },
    getRule(ruleId) {
        return axios.get(domain+"loyaltyRule/"+ruleId);
    },
    getFraudRules() {
        return axios.get(domain+"fraudRules");
    },
    createFraudRule(ruleData){
        return axios.post(domain+"createFraudRuleConfig", ruleData)
    },
    getPaymentModes() {
        return axios.get(domain+"paymentModes");
    },
    updateRuleStatus(ruleData) {
        return axios.post(domain+"updateRuleStatus", ruleData)
    },
    createOutletProduct(outletProductData){
        return axios.post(domain+"createOutletProduct", outletProductData)
    },
    editOutletProduct(outletProductData) {
        return axios.post(domain+"editOutletProduct", outletProductData)
    },
    editPartner(partnerData) {
        return axios.post(domain+"editPartner", partnerData)
    },
    addPartner(partnerData) {
        return axios.post(domain+"addPartner", partnerData)
    },
    deletePartner(partnerId) {
        return axios.get(domain+"deletePartner/"+partnerId);
    },
    deleteOutletProduct(outletProductId) {
        return axios.get(domain+"deleteOutletProduct/"+outletProductId);
    },
    updateFraudRuleStatus(ruleData) {
        return axios.post(domain+"updateFraudRuleStatus", ruleData)
    },
    redeemVoucher(voucherData) {
        return axios.post(domain+"redeemVoucher", voucherData)
    },
    saveTierSettings(tierSettings){
        return axios.post(domain+"saveTierSettings",tierSettings);
    },
    updateVoucherExpiryDate(voucherExpiryData){
        return axios.post(domain+"updateVoucherExpiryDate",voucherExpiryData);
    },
    pushSTK(paymentData) {
        return axios.post(domain+"pushSTK",paymentData);
    },

    getTierSettings() {
        return axios.get(domain+"tierSettings");
    },

    upgradeCustomerTier(customerTierData) {
        return axios.post(domain+"upgradeCustomerTier",customerTierData);
    },
    runCustomerTierExpiry() {
        return axios.get(domain+"runCustomerTierExpiry");
    },

    getVoucherRedemptions(filterData){
        return axios.get(domain+"customerVoucherRedemptions/"+filterData.outletId+"/"+filterData.startDate+"/"+filterData.endDate+"?page="+filterData.page)
    },
    getCorporateVoucherRedemptions(filterData){
        return axios.get(domain+"corporateVoucherRedemptions/"+filterData.outletId+"/"+filterData.startDate+"/"+filterData.endDate+"/"+filterData.customerId+"?page="+filterData.page)
    },
    verifyVoucherCode(voucherCode) {
        return axios.get(domain+"verifyVoucherCode/"+voucherCode);
    },
    getInstitutionalCustomers() {
        return axios.get(domain+"institutionalCustomers");
    },
    checkMpesaTransaction(billRef) {
        return axios.get(domain+"checkMpesaTransaction/"+billRef);
    },
    getVoucherRedemptionsPostings() {
        return axios.get(domain+"voucherRedemptions");
    },
    getVoucherRedemptionsPosting(voucherId) {
        return axios.get(domain+"voucherRedemption/"+voucherId);
    },
    getVoucherRedemptionItems(voucherItemId) {
        return axios.get(domain+"voucherRedemptionItems/"+voucherItemId);
    },
    searchCustomerVouchers(voucherCode) {
        return axios.get(domain+"searchCustomerVouchers/"+voucherCode);
    },
    saveReportRecipientsSettings(reportSettingsData) {
        return axios.post(domain+"addReportSettings",reportSettingsData);
    },
    editReportRecipientsSettings(reportSettingsData) {
        return axios.post(domain+"editReportSettings",reportSettingsData);
    },
    getReportRecipientSettings() {
        return axios.get(domain+"reportRecipientSettings");
    },
    deleteReportRecipientSettings(reportSettingsId) {
        return axios.get(domain+"deleteReportRecipientSettings/"+reportSettingsId);
    },
    triggerMonthlyReports() {
        return axios.get(domain+"generateAndSendMonthlyReports");
    },
    downloadCustomerVoucherReceipt(voucherId, voucherType){
        return axios.get(domain+"downloadVoucherReceipt?voucherId="+voucherId+"&voucherType="+voucherType);
    },
    exportCustomerVoucherUsageReport(from_date, to_date,username,voucherUsage){
        return axios.get(domain+"exportCustomerVoucherUsageReport?from_date="+from_date+"&to_date="+to_date+"&username="+username+"&voucherUsage="+voucherUsage);
    },
    exportInstitutionVoucherUsageReport(from_date, to_date,username,voucherUsage){
        return axios.get(domain+"exportInstitutionVoucherUsageReport?from_date="+from_date+"&to_date="+to_date+"&username="+username+"&voucherUsage="+voucherUsage);
    },
    resendVoucherToNetsuite(voucherId){
        return axios.get(domain+"resendVoucherToNetsuite/"+voucherId);
    },
    addParkingRate(parkingRateData) {
        return axios.post(domain+"addParkingRate",parkingRateData);
    },
    editParkingRate(parkingRateData) {
        return axios.post(domain+"editParkingRate",parkingRateData);
    },
    getParkingRates(){
        return axios.get(domain+"parkingRates");
    },
}
